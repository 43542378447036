/* @font-face {
  font-family: KaTeX_Main;
  font-style: normal;
  font-weight: 400;
  src: url(guides/css/fonts/KaTeX_Main-Regular.woff2) format("woff2"),
    url(guides/css/fonts/KaTeX_Main-Regular.woff) format("woff"),
    url(guides/css/fonts/KaTeX_Main-Regular.ttf) format("truetype");
} */
html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, "Segoe UI", Arial, freesans, sans-serif;
  font-weight: 200;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  color: #000;
  font-weight: 400;
  box-sizing: border-box;
}
.container .svg-icon {
  vertical-align: middle;
}
.container .pointer {
  cursor: pointer;
}
.container .items-center {
  display: flex;
  align-items: center;
}
.container .header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  width: 100%;
  background-color: #fff5e8;
}
.container .header-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 960px;
  box-sizing: border-box;
  padding: 1.14rem;
  color: #000;
}
@media only screen and (max-width: 960px) {
  .container .header-2 {
    width: 100%;
  }
}
.container .header-2 .logo {
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
}
.container .main-1 {
  padding: 8em 1.2em 0;
  width: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 960px) {
  .container .main-1 {
    width: 100%;
  }
}
.container .main-1 .btn-center {
  color: #868e96;
  border: 1px solid #868e96;
  border-radius: 5em;
  padding: 0 0.5em;
  font-weight: 700;
  font-size: 0.78rem;
  line-height: 1;
}
.container .main-1 .h1 {
  text-align: center;
  font-size: 4.57rem;
  font-weight: 700;
  line-height: 1.3;
}
@media only screen and (max-width: 800px) {
  .container .main-1 .h1 {
    font-size: 2.86rem;
  }
}
.container .main-1 .h1.h1-FD7E14 {
  color: #fd7e14;
}
.container .main-1 .start-btn {
  border-color: #000;
  width: 10.6rem;
  height: 3.2rem;
  line-height: 3.2rem;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 3px;
  font-weight: 600;
}
.container .main-1 .start-btn:hover {
  border-color: #000;
  background-color: #d9d9d9;
  color: #000;
}
.container .main-1 .tags {
  margin-top: 9.15rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.container .main-1 .tags .tag {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  margin-bottom: 1.43rem;
}
.container .main-1 .tags .tag:nth-child(2) {
  margin-left: 1rem;
  margin-right: 1rem;
}
.container .main-1 .tags .tag-text {
  font-weight: 600;
  font-size: 1rem;
}
.container .main-1 .tags .icon-svg {
  margin-right: 0.5rem;
}
.container .main-1 .bg-box {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-width: 100vw;
  overflow: hidden;
}
.container .main-1 .bg-box .bg-img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 859px;
  height: 100%;
}
.container .main-2 {
  border-top: 1px solid #e9e9e9;
  padding-top: 6.86rem;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 720px) {
  .container .main-2 {
    width: 100%;
  }
}
.container .main-2 .title {
  font-size: 2.285rem;
  font-weight: 600;
  margin-left: 0.5em;
}
.container .main-2 .subtitle {
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 0.71rem;
  font-size: 1.14rem;
  font-weight: 400;
  color: #868e96;
  text-align: center;
}
.container .main-2 .cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.container .main-2 .cards .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.143rem;
  text-align: center;
  width: 360px;
  box-sizing: border-box;
}
@media only screen and (max-width: 720px) {
  .container .main-2 .cards .card {
    width: 100%;
  }
}
.container .main-2 .cards .card .svg-box {
  border-radius: 50%;
  background: #e7f6f8;
  width: 3.57rem;
  height: 3.57rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #15aabf;
}
.container .main-2 .cards .card .card-title {
  font-size: 1.714rem;
  font-weight: 600;
  margin: 0.71rem 0;
}
.container .main-2 .cards .card .card-content {
  font-size: 1.143rem;
  color: #868e96;
}
.container .main-3 {
  width: 960px;
  margin-top: 9.15rem;
  padding: 0 16px;
  box-sizing: border-box;
}
@media only screen and (max-width: 960px) {
  .container .main-3 {
    width: 100%;
  }
}
.container .main-3 .main3-cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.container .main-3 .main3-cards .main3-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 0.71rem;
  box-sizing: border-box;
}
@media only screen and (max-width: 576px) {
  .container .main-3 .main3-cards .main3-card {
    width: 100%;
  }
}
.container .main-3 .main3-cards .main3-card .title-box {
  display: flex;
  align-items: center;
}
.container .main-3 .main3-cards .main3-card .title-box .svg-box {
  margin-right: 0.5em;
}
.container .main-3 .main3-cards .main3-card .title-box .title {
  font-size: 2rem;
  font-weight: 600;
}
.container .main-3 .main3-cards .main3-card .content {
  width: 100%;
  color: #868e96;
  font-size: 1.14rem;
  margin: 0.71rem 0 1.43rem;
}
.container .main-3 .main3-cards .main3-card .info-list .info-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.container .main-3 .main3-cards .main3-card .info-list .info-item .icon-box {
  color: #40c057;
  background: #ecf9ee;
  margin-right: 0.86rem;
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .main-3 .main3-cards .main3-card .info-list .info-item .icon-box.yellow {
  color: #fab005;
  background: #fef7e6;
}
.container .main-3 .main3-cards .main3-card .info-list .info-item .info-text {
  flex: 1;
}
.container .main4-box {
  padding: 0 16px;
}
.container .main4-title {
  font-size: 2.285rem;
  font-weight: 600;
  text-align: center;
  margin-top: 9.14rem;
}
.container .main4-subtitle {
  font-size: 1.14rem;
  color: #868e96;
  text-align: center;
  margin-top: 0.71rem;
  margin-bottom: 1.43rem;
}
.container .main4 {
  width: 688px;
  margin-top: 1.43rem;
  padding: 2.28rem;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  border: 1px solid #e9e9e9;
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  alalign-items: center;
}
@media only screen and (max-width: 688px) {
  .container .main4 {
    width: 100%;
  }
}
.container .main4 .main4-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (max-width: 480px) {
  .container .main4 .main4-card {
    flex: unset;
    width: 100%;
  }
}
.container .main4 .main4-card .main4-card-title {
  font-size: 1.714rem;
  font-weight: 600;
}
.container .main4 .main4-card .main4-card-title .large {
  font-size: 2.058rem;
}
.container .main4 .main4-card .main4-card-title .small {
  font-size: 1.14rem;
}
.container .main4 .main4-card .main4-card-content {
  font-size: 1.14rem;
  color: #868e96;
  margin: 0.3rem 0;
}
.container .main4 .main4-card .main4-card-list .main4-card-item {
  font-size: 1.14rem;
  color: #868e96;
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
}
.container .main4 .main4-card .main4-card-list .main4-card-item .dot {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background: #868e96;
}
.container .main4 .main4-card .main4-card-list .main4-card-item .list-content {
  margin-left: 0.5rem;
}
.container .main4 .plus-icon {
  align-self: center;
  margin: 0 2rem 0 1rem;
}
@media only screen and (max-width: 480px) {
  .container .main4 .plus-icon {
    margin: 0;
    width: 1rem;
    display: none;
  }
}
.container .start-btn2 {
  border-color: #000;
  width: 17.3rem;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 3px;
  font-weight: 600;
  margin-top: 2.286rem;
}
.container .start-btn2:hover {
  border-color: #000 !important;
  background-color: #d9d9d9 !important;
  color: #000 !important;
}
.container .btn-foot {
  margin-top: calc(4rem + 50px);
  margin-bottom: 1rem;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  border: 1px solid #e9e9e9;
  font-weight: 400;
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
}
.container .btn-foot .link {
  color: #228be6;
  margin-left: 0.5rem;
}
.container .main-foot {
  padding: 20px 0;
  font-size: 1.3rem;
}/*# sourceMappingURL=index.css.map */